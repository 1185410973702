import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getList } from "../../Redux/Payment/actions";

function List({ useAuth, getList, list, onSendEmail }) {

  const tableConfig = {
    title: "All Cart",
    is_payment: true,
    tableColumns: [
      {name: "operation", selector: 'checkout_url', width: '2%'},
      {name: "Name", selector: 'customer_name', width: '50%'},
      {name: "Email", selector: 'customer_email', width: '15%'},
      {name: "Price", selector: 'customer_total', width: '15%'},
      {name: "Created At", selector: 'created_at', width: '15%'},
      {name: "ID", selector: 'id', width: '2%'},
    ],
    filterByColumn: 'type',
    searchByColumn: 'full_name',
    addPath: '/payment/add',
    updatePath: "/payment/update",
    noDataMessage: "No Cart package",
    updateLinkColumn: 'full_name',
    sendEmailByBookingId: (id) => {
      onSendEmail(id)
    },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.payment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)), 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
