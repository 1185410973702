import {
  BOOKING_LIST_LOADING,
  GET_BOOKING_LIST,
  GET_BOOKING_LIST_ERROR,
  GET_BOOKING_BY_ID_LOADING,
  GET_BOOKING_BY_ID_SUCCESS,
  GET_BOOKING_BY_ID_ERROR,
  SENDING_EMAIL,
  EMAIL_SENT,
  SENDING_EMAIL_ERROR,
  GET_INVOICE_LOADING,
  GET_INVOICE,
  GET_INVOICE_ERROR,
  INQUIRY_LIST_LOADING,
  GET_INQUIRY_LIST,
  GET_INQUIRY_LIST_ERROR,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";

export const getList = (tokenData) => async (dispatch) => {
  dispatch({
    type: BOOKING_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/front/packagebooking/all`, {
      headers: {
        Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: GET_BOOKING_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_BOOKING_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const getEntityById = (tokenData, id) => async (dispatch) => {
  dispatch({
    type: GET_BOOKING_BY_ID_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/front/packagebooking/single/${id}`, {
      headers: {
        Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: GET_BOOKING_BY_ID_SUCCESS,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_BOOKING_BY_ID_ERROR,
        payload: error.response,
      });
    });
};

export const sendEmail = (id) => async (dispatch) => {
  dispatch({
    type: SENDING_EMAIL,
  });
  await axios
    .post(
      `${BASE_URL}api/v1/admin/front/packagebooking/sendemail/${id}`,
      {},
      {
        headers: {
          Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
        },
      }
    )
    .then(function (response) {
      dispatch({
        type: EMAIL_SENT,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: SENDING_EMAIL_ERROR,
        payload: error.response,
      });
    });
};

export const getInvoice = (id) => async (dispatch) => {
  dispatch({
    type: GET_INVOICE_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/front/bookinginvoice/single/${id}`, {
      headers: {
        Authorization: `${localStorage.token_type} ${localStorage.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: GET_INVOICE,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_INVOICE_ERROR,
        payload: error.response,
      });
    });
};

export const getInquiryList = (tokenData) => async (dispatch) => {
  dispatch({
    type: INQUIRY_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/front/contactmessage/all`, {
      headers: {
        Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
      },
    })
    .then(function (response) {
      dispatch({
        type: GET_INQUIRY_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_INQUIRY_LIST_ERROR,
        payload: error.response,
      });
    });
};
