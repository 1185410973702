 import {
  MENUTYPE_LIST_LOADING,
  GET_MENUTYPE_LIST,
  GET_MENUTYPE_LIST_ERROR,
  GET_MENUTYPE_BY_ID_LOADING,
  GET_MENUTYPE_BY_ID_SUCCESS,
  GET_MENUTYPE_BY_ID_ERROR,
  DELETEING_MENUTYPE,
  DELETE_MENUTYPE,
  DELETE_MENUTYPE_ERROR,
  DELETED_MENUTYPE_LIST_LOADING,
  GET_DELETED_MENUTYPE_LIST,
  GET_DELETED_MENUTYPE_LIST_ERROR,
  RESTORE_MENUTYPE_LIST_LOADING,
  RESTORE_MENUTYPE_LIST,
  RESTORE_MENUTYPE_LIST_ERROR,   
  PERMA_DELETE_MENUTYPE,
  PERMA_DELETE_MENUTYPE_ERROR,
  PERMA_DELETEING_MENUTYPE,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const getList = (tokenData) => async (dispatch) => {
  dispatch({
    type: MENUTYPE_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/menu/type/active`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_MENUTYPE_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_MENUTYPE_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const getEntityById = (tokenData, id) => async (dispatch) => {
  dispatch({
    type: GET_MENUTYPE_BY_ID_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/menu/type/active/${id}`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_MENUTYPE_BY_ID_SUCCESS,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_MENUTYPE_BY_ID_ERROR,
        payload: error.response,
      })      
    });
};

export const getDeletedList = (tokenData) => async (dispatch) => {
  dispatch({
    type: DELETED_MENUTYPE_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/menu/type/deleted`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_DELETED_MENUTYPE_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_DELETED_MENUTYPE_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const deleteEntityById = (id, tokenData) => async (dispatch) => {
  const data = {bulk: [id]}
  dispatch({
    type: DELETEING_MENUTYPE,
  });
  await axios
    .post(`${BASE_URL}api/v1/admin/menu/type/bulk/delete`, data, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: DELETE_MENUTYPE,
        payload: response,
      })      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: DELETE_MENUTYPE_ERROR,
        payload: error.response,
      })
      message.error(error.response.data.message);
    });
};

export const permanentDeleteEntity = (idList) => async (dispatch) => {
  dispatch({
    type: PERMA_DELETEING_MENUTYPE,
  });
  let bulk = {bulk: idList}
  await axios
    .post(`${BASE_URL}api/v1/admin/menu/type/bulk/perma-delete`, bulk, {
      headers: {
        'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: PERMA_DELETE_MENUTYPE,
        payload: response,
      })      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: PERMA_DELETE_MENUTYPE_ERROR,
        payload: error.response,
      })      
      message.error(error.response.data.message);
    });
};

export const restoretDeleteEntity = (idList, history) => async (dispatch) => {
  dispatch({
    type: RESTORE_MENUTYPE_LIST_LOADING,
  });
  let bulk = {bulk: idList}
  await axios
    .post(`${BASE_URL}api/v1/admin/menu/type/bulk/restore`, bulk, {
      headers: {
        'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: RESTORE_MENUTYPE_LIST,
        payload: response,
      })      
      message.success(response.data.message);
        history.push("/menu-types")
    })
    .catch(function (error) {
      dispatch({
        type: RESTORE_MENUTYPE_LIST_ERROR,
        payload: error.response,
      })      
      message.error(error.response.data.message);
    });
};

