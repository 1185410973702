import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getList, sendEmail } from "../../Redux/Booking/actions";

function List({ useAuth, getList, list, onSendEmail }) {

  const tableConfig = {
    title: "All Booking",
    is_booking: true,
    tableColumns: [
      {name: "Name", selector: 'full_name', width: '60%'},
      {name: "Email", selector: 'email', width: '15%'},
      {name: "Price", selector: 'total_price', width: '10%'},
      {name: "Booking At", selector: 'created_at', width: '25%'},
      {name: "ID", selector: 'id', width: '2%'},
      {name: "operation", selector: 'travellerinfo_url', width: '5%'}
    ],
    filterByColumn: 'type',
    searchByColumn: 'full_name',
    addPath: '/booking/add',
    updatePath: "/booking/update",
    noDataMessage: "No Booking exists",
    updateLinkColumn: 'full_name',
    sendEmailByBookingId: (id) => {
      onSendEmail(id)
    },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.booking,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)), 
    onSendEmail: (id) => dispatch(sendEmail(id))   
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
