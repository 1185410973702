import {
  Table,
  Modal,
  Spin,
  Anchor,
  TreeSelect,
  Space,
  Popconfirm,
} from "antd";
import "antd/dist/antd.css";
import React, { useEffect, useState } from "react";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import { Link, useHistory } from "react-router-dom";
import { arrayMoveImmutable } from "array-move";
import Moment from "react-moment";

const { TreeNode } = TreeSelect;

const Status = ({ id, status_checked }) => (
  <div className="status">
    <span className="form-check">
      <input
        className="form-check-input"
        type="checkbox"
        checked={status_checked == 0 ? false : true}
        id={id}
      />
      <label className="form-check-label" for={id}></label>
    </span>
  </div>
);

const FilterComponent = ({ searchText, onSearch, searchByColumn }) => (
  <div>
    <div className="input-search">
      <input
        id="search"
        className="form-control"
        type="text"
        placeholder={`Search By key...`}
        aria-label="Search Input"
        value={searchText}
        onChange={onSearch}
      />
    </div>
  </div>
);

const Sorting = ({
  searchText,
  setSearchText,
  setFilter,
  filterText,
  filterOptionTitle,
  filterOptions,
  searchByColumn,
  applyFilter,
}) => {
  const handleClear = () => {
    if (searchText) {
      setSearchText("");
    }
  };

  return (
    <>
      {applyFilter && (
        <div className="category-sorting">
          <label for="catlist">{filterOptionTitle}:</label>
          <div className="custom_select">
            <TreeSelect
              showSearch
              style={{ width: "100%" }}
              dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
              placeholder="Please select"
              allowClear
              treeDefaultExpandAll
              onSelect={(e) => {
                setFilter(e);
              }}
              value={filterText}
            >
              <TreeNode value="all" title="All" />
              {filterOptions?.map((parent) => {
                const { value, text, children } = parent;
                return (
                  <TreeNode key={value} value={value} title={text}>
                    {children?.length !== 0 &&
                      children?.map((child) => {
                        const { value, text, children } = child;
                        return (
                          <TreeNode key={value} value={value} title={text}>
                            {children.length !== 0 &&
                              children?.map((gChild) => {
                                const { value, text } = gChild;
                                return (
                                  <TreeNode
                                    key={value}
                                    value={value}
                                    title={text}
                                  />
                                );
                              })}
                          </TreeNode>
                        );
                      })}
                  </TreeNode>
                );
              })}
            </TreeSelect>
          </div>
        </div>
      )}

      <FilterComponent
        onSearch={(e) => setSearchText(e.target.value)}
        onClear={handleClear}
        searchText={searchText}
        searchByColumn={searchByColumn}
      />
    </>
  );
};

const DragHandle = sortableHandle(() => (
  <i className="bi-arrows-expand" style={{ cursor: "grab", color: "#999" }}></i>
));
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const CustomDataTable = ({
  title,
  filterOptionTitle,
  updateLinkColumn,
  filterOptions,
  tableData,
  tableColumns,
  searchByColumn,
  filterByColumn,
  applyFilter,
  updatePath,
  updateParentPath,
  addPath,
  addTypePath,
  addTypeText,
  restorePath,
  dataToList,
  deleteBtnText,
  deleteItemsByIdList,
  restoreItemsByIdList,
  sortItemsByIdList,
  is_booking,
  is_inquiry,
  is_menu,
  is_payment,
  sendEmailByBookingId,
}) => {
  const [dataSource, setDataSource] = useState(() => tableData);
  const [selectedItems, setSelectedItems] = useState();
  const [searchText, setSearchText] = useState("");
  const [filterText, setFilter] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState();

  // const openNotificationWithIcon = (type, message) => {
  //   notification.open({
  //     type: type,
  //     message: message,
  //      });
  // };

  const history = useHistory();

  useEffect(() => {
    if (tableData) {
      setDataSource(() => tableData);
    }

    if (filterText) {
      if (filterText === "all") {
        setDataSource(tableData);
      } else if (title == "Packages") {
        setDataSource(
          tableData.filter(
            (item) =>
              item[filterByColumn] && item[filterByColumn].includes(filterText)
          )
        );
      } else {
        setDataSource(
          tableData.filter(
            (item) => item[filterByColumn] && item[filterByColumn] == filterText
          )
        );
      }
    }

    if (searchText) {
      setDataSource(
        dataSource.filter(
          (item) =>
            item[searchByColumn] &&
            item[searchByColumn]
              .toString()
              .toLowerCase()
              .includes(searchText.toLowerCase())
        )
      );
    }
  }, [tableData, searchText, filterText]);

  const handleCopy = (url) => {
    navigator.clipboard.writeText(url);
  };

  const handleOrderChild = (record) => {
    setDataSource(record.children);
  };

  const columns = tableColumns.map((x) => {
    return {
      title: x.name,
      dataIndex: x.selector,
      width: x.width,
      ...(x.name === "Order" && {
        render: () => <DragHandle />,
        className: "drag-visible",
      }),
      ...(x.name === "Status" && { className: "drag-visible" }),
      ...(is_booking &&
        x.name === "operation" && {
          title: "Links",
          dataIndex: "operation",
          render: (_, record) =>
            data.length >= 1 ? (
              <Space size="middle">
                <Popconfirm
                  title="Copy to Clipboard?"
                  onConfirm={() => handleCopy(record.travellerinfo_url)}
                >
                  <a className="btn btn-sm btn-primary">
                    <i className="bi-clipboard-check" /> Copy
                  </a>
                </Popconfirm>
                {/* <a
                  className="btn btn-sm btn-warning"
                  onClick={() => {
                    setModalData(record);
                    setModalOpen(true);
                  }}
                >
                  <i className="bi-eye-fill" /> Invoice
                </a> */}
              </Space>
            ) : null,
        }),
        ...(is_payment &&
          x.name === "operation" && {
            title: "Urls",
            dataIndex: "operation",
            render: (_, record) =>
              data.length >= 1 ? (
                <Space size="middle">
                  <Popconfirm
                    title="Copy to Clipboard?"
                    onConfirm={() => handleCopy(record.checkout_url)}
                  >
                    <a className="btn btn-sm btn-primary">
                      <i className="bi-clipboard-check" /> Copy
                    </a>
                  </Popconfirm>
                </Space>
              ) : null,
          }),

      ...(is_inquiry &&
        x.name === "operation" && {
          title: "",
          dataIndex: "operation",
          render: (_, record) =>
            data.length >= 1 ? (
              <Space size="middle">
                <a
                  className="btn btn-sm btn-warning"
                  onClick={() => {
                    setModalData(record);
                    setModalOpen(true);
                  }}
                >
                  <i className="bi-eye-fill" /> View Detail
                </a>
              </Space>
            ) : null,
        }),
      ...(is_menu &&
        x.name === "operation" && {
          title: "Children",
          dataIndex: "operation",
          render: (_, record) =>
            record.children?.length > 1 && (
              <a
                className="btn btn-sm btn-warning"
                onClick={() => handleOrderChild(record)}
              >
                VIEW
              </a>
            ),
        }),
    };
  });

  if (
    title !== "Categories" &&
    title !== "Deleted Categorie(s)" &&
    title !== "Menu" &&
    title !== "Articles" &&
    title !== "Deleted Menu(s)" &&
    title !== "Media" &&
    title !== "Faqs" &&
    title !== "Comments"
  ) {
    columns.splice(0, 0, {
      title: "S.N",
      dataIndex: "sn",
      width: "3%",
    });
  }

  let data = dataSource.map((x, idx) => {
    return {
      ...x,
      [updateLinkColumn]: (
        <Link
          className={`link`}
          to={
            updateParentPath
              ? `${updateParentPath}/${x.id}`
              : `${updatePath}/${x.id}`
          }
        >
          {x[updateLinkColumn]}
        </Link>
      ),
      sn: idx + 1,
      index: idx + 1,
      key: "" + (idx + 1),
      status: <Status id={x.id} status_checked={x.status} />,
      approval_status: <Status id={x.id} status_checked={x.approval_status} />,
      updated_at: (
        <Moment fromNow ago>
          {x.updated_at}
        </Moment>
      ),
      created_at: (
        <Moment fromNow ago>
          {x.created_at}
        </Moment>
      ),
      ...(x.children && {
        children: x.children?.map((i, jdx) => {
          return {
            ...i,
            [updateLinkColumn]: (
              <Link className={`link`} to={`${updatePath}/${i.id}`}>
                {i[updateLinkColumn]}
              </Link>
            ),
            index: Math.floor("" + (idx + 1) + (jdx + 1)),
            key: "" + (idx + 1) + (jdx + 1),
            status: <Status id={i.id} status_checked={i.status} />,
            approval_status: (
              <Status id={x.id} status_checked={x.approval_status} />
            ),
            updated_at: (
              <Moment fromNow ago>
                {i.updated_at}
              </Moment>
            ),
            ...(i.children && {
              children: i.children?.map((k, kdx) => {
                return {
                  ...k,
                  [updateLinkColumn]: (
                    <Link className={`link`} to={`${updatePath}/${k.id}`}>
                      {k[updateLinkColumn]}
                    </Link>
                  ),
                  index: Math.floor("" + (idx + 1) + (jdx + 1) + (kdx + 1)),
                  key: "" + (idx + 1) + (jdx + 1) + (kdx + 1),
                  status: <Status id={k.id} status_checked={k.status} />,
                  approval_status: (
                    <Status id={x.id} status_checked={x.approval_status} />
                  ),
                  updated_at: (
                    <Moment fromNow ago>
                      {k.updated_at}
                    </Moment>
                  ),
                };
              }),
            }),
          };
        }),
      }),
    };
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        [].concat(data),
        oldIndex,
        newIndex
      ).filter((el) => !!el);
      setDataSource(newData);
      title === "Menu"
        ? sortItemsByIdList({
            type: data.filter((itm) => itm.index === newIndex)[0].type.id,
            parent_id: data.filter((itm) => itm.index === newIndex)[0]
              .parent_id,
            item_array: newData.map((a) => a.id),
          })
        : sortItemsByIdList({
            item_array: newData.map((a) => a.id),
          });
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  let DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = data.findIndex((x) => x.index === restProps["data-row-key"]);
    return <SortableItem index={index} {...restProps} />;
  };

  const handleDelete = () => {
    Modal.confirm({
      title: "Delete",
      content: "Are you sure, you want to delete?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: async () => {
        deleteItemsByIdList(selectedItems.map((x) => x.id));
        //openNotificationWithIcon('success', `${title} deleted successfully`);
        setSelectedItems();
      },
    });
  };

  const handleRestore = () => {
    Modal.confirm({
      title: "Restore",
      content: "Are you sure, you want to restore?",
      okText: "Yes",
      cancelText: "Cancel",
      onOk: () => {
        restoreItemsByIdList(selectedItems.map((x) => x.id));
        //openNotificationWithIcon('success', `${title} restore successfully`);
        setSelectedItems();
        history.push(restorePath);
      },
    });
  };

  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {
      setSelectedItems(selectedRows);
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectedItems(selectedRows);
    },
    onSelectNone: () => {
      setSelectedItems();
    },
  };

  return (
    <>
      <div className="common-module">
        <div className="action-bar">
          <div className="title">
            <h1>{title}</h1>
          </div>
          <div className="action-slot">
            <ul>
              {addPath && (
                <li>
                  <Link to={addPath} className="btn btn-primary" id="add_new">
                    <i className="bi-plus-circle"></i> Add New
                  </Link>
                </li>
              )}

              {localStorage.admin_type == "super" && addTypePath && (
                <li>
                  <Link
                    to={addTypePath}
                    className="btn btn-warning"
                    id="add_new"
                  >
                    <i className="bi-plus"></i> {addTypeText}
                  </Link>
                </li>
              )}
              {selectedItems &&
              selectedItems.length &&
              localStorage.admin_type == "super" ? (
                <li>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={handleDelete}
                  >
                    <i className="bi-x"></i> {deleteBtnText}
                  </button>
                </li>
              ) : (
                ""
              )}
              {restoreItemsByIdList &&
              selectedItems &&
              selectedItems.length &&
              localStorage.admin_type == "super" ? (
                <li>
                  <button
                    type="button"
                    className="btn btn-warning"
                    onClick={handleRestore}
                  >
                    <i className="bi-clock"></i> Restore
                  </button>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className="common-module data-table bg-white">
        <div className="sorting-wrapper py-2">
          <div className="alert-text">
            <i className="bi-info-circle text-primary"></i> {data.length}{" "}
            {title} are listed
          </div>
          <Sorting
            {...{
              filterOptionTitle,
              filterOptions,
              searchText,
              setFilter,
              filterText,
              setSearchText,
              searchByColumn,
              filterByColumn,
              applyFilter,
            }}
          />
        </div>

        <Table
          columns={columns}
          pagination={is_menu && { pageSize: 30 }}
          rowSelection={{ ...rowSelection }}
          rowKey="index"
          expandableRowIcon={"+"}
          loading={dataToList.loading && <Spin />}
          dataSource={data}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow,
            },
          }}
        />

        {is_booking && (
          <Modal
            title="Modal 1000px width"
            centered
            open={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            width={1000}
          >
            {console.log(modalData)}
            <p>some contents...</p>
            <p>some contents...</p>
            <p>some contents...</p>
          </Modal>
        )}

        {is_inquiry && modalData && (
          <Modal
            title={"Inquiry from " + modalData.type + " Page"}
            open={modalOpen}
            onOk={() => {
              window.location.href = `mailto:${modalData.email}`;
              setModalOpen(false);
            }}
            onCancel={() => setModalOpen(false)}
            okText="Response it"
          >
            <h5>Contact Address</h5>
            <p>
              {modalData.name} <br /> {modalData.email} <br />{" "}
              <a href={`tel:${modalData.url}`}>{modalData.phone_no}</a> <br />{" "}
              <a href={modalData.url} target="_blank">
                {modalData.url}
              </a>
            </p>
            <h5>Message</h5>
            <p>{modalData.message}</p>
          </Modal>
        )}
      </div>
    </>
  );
};

export default CustomDataTable;
