import {
  COMMENT_LIST_LOADING,
  GET_COMMENT_LIST,
  GET_COMMENT_LIST_ERROR,
  GET_COMMENT_BY_ID_LOADING,
  GET_COMMENT_BY_ID_SUCCESS,
  GET_COMMENT_BY_ID_ERROR,
  DELETEING_COMMENT,
  DELETE_COMMENT,
  DELETE_COMMENT_ERROR,
  DELETED_COMMENT_LIST_LOADING,
  GET_DELETED_COMMENT_LIST,
  GET_DELETED_COMMENT_LIST_ERROR,
  PERMA_DELETEING_COMMENT,
  PERMA_DELETE_COMMENT,
  PERMA_DELETE_COMMENT_ERROR,
  RESTORE_COMMENT_LIST_LOADING,
  RESTORE_COMMENT_LIST,
  RESTORE_COMMENT_LIST_ERROR
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const getList = (tokenData) => async (dispatch) => {
  dispatch({
    type: COMMENT_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/blog/comment/all`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_COMMENT_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_COMMENT_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const getEntityById = (tokenData, id) => async (dispatch) => {
  dispatch({
    type: GET_COMMENT_BY_ID_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/blog/comment/single/${id}`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_COMMENT_BY_ID_SUCCESS,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_COMMENT_BY_ID_ERROR,
        payload: error.response,
      });
    });
};

export const getDeletedList = (tokenData) => async (dispatch) => {
  dispatch({
    type: DELETED_COMMENT_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/blog/comment/rejected`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_DELETED_COMMENT_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_DELETED_COMMENT_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const deleteEntityById = (idList) => async (dispatch) => {
  dispatch({
    type: DELETEING_COMMENT,
  });
  let bulk = {bulk: idList}
  await axios
    .post(`${BASE_URL}api/v1/admin/blog/comment/bulk/delete`, bulk, {
      headers: {
        'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: DELETE_COMMENT,
        payload: response,
      })      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: DELETE_COMMENT_ERROR,
        payload: error.response,
      })
      message.success(error.response.data.message);
    });
};

// export const permanentDeleteEntity = (idList) => async (dispatch) => {
//   dispatch({
//     type: PERMA_DELETEING_COMMENT,
//   });
//   let bulk = {bulk: idList}
//   await axios
//     .post(`${BASE_URL}api/v1/admin/COMMENT/bulk/perma-delete`, bulk, {
//       headers: {
//         'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
//       }
//     })
//     .then(function (response) {
//       dispatch({
//         type: PERMA_DELETE_COMMENT,
//         payload: response,
//       })      
//       message.success(response.data.message);
//     })
//     .catch(function (error) {
//       dispatch({
//         type: PERMA_DELETE_COMMENT_ERROR,
//         payload: error.response,
//       })      
//       message.error(error.response.data.message);
//     });
// };

// export const restoretDeleteEntity = (idList) => async (dispatch) => {
//   dispatch({
//     type: RESTORE_COMMENT_LIST_LOADING,
//   });
//   let bulk = {bulk: idList}
//   await axios
//     .post(`${BASE_URL}api/v1/admin/COMMENT/bulk/restore`, bulk, {
//       headers: {
//         'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
//       }
//     })
//     .then(function (response) {
//       dispatch({
//         type: RESTORE_COMMENT_LIST,
//         payload: response,
//       })
//       message.success(response.data.message);
//     })
//     .catch(function (error) {
//       dispatch({
//         type: RESTORE_COMMENT_LIST_ERROR,
//         payload: error.response,
//       })      
//       message.error(error.response.data.message);
//     });
// };