import {
  CREATING_REEL,
  ERROR_REEL_CREATE,
  UPDATING_REEL,
  REEL_CREATED,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
  REEL_UPDATED,
  ERROR_REEL_UPDATE,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  loadedRecently: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATING_REEL:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case REEL_CREATED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case ERROR_REEL_CREATE:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    case UPDATING_REEL:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case REEL_UPDATED:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
    case ERROR_REEL_UPDATE:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case SET_UPDATED_RECENTLY_TO_FALSE:
      return {
        ...state,
        loadedRecently: false
      }
    case UPDATED_RECENTLY:
      return {
        ...state,
        loadedRecently: true
      }
    default:
      return state;
  }
};

export default reducer;
