import {
  CREATING_COMMENT,
  UPDATING_COMMENT,
  COMMENT_CREATED,
  COMMENT_UPDATED,
  ERROR_COMMENT_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
  ERROR_COMMENT_UPDATE
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
};
export const createEntity =
  (formData, tokenData, exitNow, history) => async (dispatch) => {
    dispatch({
      type: CREATING_COMMENT,
    });
    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );   

     let webKitFormData = new FormData();
    // if (data.package_id) {
    //   for (let i = 0; i < data.package_id.length; i++) {
    //     webKitFormData.append("package_id[]", data.package_id[i]);
    //   }
    // } ["package_id"].forEach(
    //   (e) => delete data[e]
    // );

    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });

    await axios
      .post(`${BASE_URL}api/v1/admin/blog/comment/add`, webKitFormData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: COMMENT_CREATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        if (exitNow) {
          history.push("/blog/comments");
          message.success(response.data.message);
        }
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_COMMENT_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };

export const updateEntityInfo =
  (formData, tokenData, id) => async (dispatch) => {
    dispatch({
      type: UPDATING_COMMENT,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );

     let webKitFormData = new FormData();
    // if (data.package_id) {
    //   for (let i = 0; i < data.package_id.length; i++) {
    //     webKitFormData.append("package_id[]", data.package_id[i]);
    //   }
    // } 
    // ["package_id"].forEach(
    //   (e) => delete data[e]
    // );
    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });
    webKitFormData.append("_method", "put");

    await axios
      .post(`${BASE_URL}api/v1/admin/blog/comment/edit/${id}`, webKitFormData, {
        headers: {
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: COMMENT_UPDATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_COMMENT_UPDATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };

