export const REEL_LIST_LOADING = "REEL_LIST_LOADING";
export const GET_REEL_LIST = "GET_REEL_LIST";
export const GET_REEL_LIST_ERROR = "GET_REEL_LIST_ERROR";

export const GET_REEL_BY_ID_LOADING = "GET_REEL_BY_ID_LOADING";
export const GET_REEL_BY_ID_SUCCESS = "GET_REEL_BY_ID_SUCCESS";
export const GET_REEL_BY_ID_ERROR = "GET_REEL_BY_ID_ERROR";

export const DELETEING_REEL = "DELETEING_REEL";
export const DELETE_REEL = "DELETE_REEL";
export const DELETE_REEL_ERROR = "DELETE_REEL_ERROR";
