import React, { useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Switch,
  Alert,
  Spin,
  DatePicker,
  InputNumber,
} from "antd";
import { useHistory } from "react-router-dom";
import DashboardActionBar from "../DashboardActionBar";
import { connect } from "react-redux";
import { getList } from "../../Redux/Package/actions";
import countryCode from "./../../lib/country-code.json";
import moment from "moment";

const { Option } = Select;
const { RangePicker } = DatePicker;

const BookingForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getPackageList,
  packageList,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading || data.loading || packageList.loading;

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  const loadPackages = async () => {
    await getPackageList(tokenData);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
    if (!packageList.data) {
      loadPackages();
    }
  }, [packageList.data]);

  useEffect(() => {
    const alert = document.querySelector(".custom-alert");
    setTimeout(() => {
      alert.classList.add("fadeout");
    }, 5000);
  }, [selectedForm.error]);

  const packages = packageList.data?.data.map((itm) => {
    return {
      label: itm.package_title,
      value: itm.id,
    };
  });

  const countryNameList = countryCode.map((itm) => {
    return {
      label: itm.name,
      value: itm.name,
    };
  });

  const countryCodeList = countryCode.map((itm) => {
    return {
      label: itm.dial_code,
      value: itm.dial_code,
    };
  });

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
            start_date: moment(values.departure_date[0]).format('YYYY-MM-DD'),
            end_date: moment(values.departure_date[1]).format('YYYY-MM-DD')
          },
          tokenData
        );
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
            start_date: moment(values.departure_date[0]).format('YYYY-MM-DD'),
            end_date: moment(values.departure_date[1]).format('YYYY-MM-DD')
          },
          tokenData,
          true,
          history
        );
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
            start_date: moment(values.departure_date[0]).format('YYYY-MM-DD'),
            end_date: moment(values.departure_date[1]).format('YYYY-MM-DD')   
          },
          tokenData,
          params.id
        );
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  // const onFinish = (values) => {
  //   if (isAddForm) {
  //     createEntity(
  //       {
  //         ...values,
  //         status: values.status ? 1 : 0,
  //       },
  //       tokenData
  //     ).then(() =>{
  //       message.success('Category is added!')
  //       loadCategory()
  //     });
  //   } else if (isUpdateForm) {
  //     updateEntityInfo(
  //       {
  //         ...values,
  //         status: values.status ? 1 : 0,
  //         is_featured: values.is_featured ? 1 : 0,
  //         description,
  //         featured_image: response.data,
  //       },
  //       tokenData,
  //       params.id
  //     ).then(() => {
  //       message.success('Category is updated!')
  //       loadEntityById()
  //       loadCategory()
  //     });
  //   }
  // };

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      trip_id: data.dataById?.data.trip_id,
      start_date: data.dataById?.data.start_date,
      end_date: data.dataById?.data.end_date,
      full_name: data.dataById?.data.full_name,
      email: data.dataById?.data.email,
      date_of_birth: data.dataById?.data.date_of_birth,
      nationality: data.dataById?.data.nationality,
      country_code: data.dataById?.data.country_code,
      mobile_number: data.dataById?.data.mobile_number,
      pickup_details: data.dataById?.data.pickup_details,
      payment_options: data.dataById?.data.payment_options,
      total_price: data.dataById?.data.total_price,
      payable_percentage: data.dataById?.data.payable_percentage,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Create Booking",
    titleToUpdate: "Update Booking",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update",
    taskCompleteAddMessage: "Booking is added!",
    taskCompleteUpdateMessage: "Booking is updated!",
    discardLink: "bookings",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module bg-white mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Full Name"
                  name="full_name"
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                    { min: 5, max: 255, message: "Invalid Name" },
                  ]}
                >
                  <Input placeholder="Full Name" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="E-mail"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Departure Date"
                  name="departure_date"
                  rules={[
                    {
                      required: true,
                      message: "Please input your Departure Date",
                    },
                  ]}
                >
                  <RangePicker
                    format={"YYYY-MM-DD"}
                    disabledDate={(current) =>
                      current && current < moment().endOf("day")
                    }
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Number of Traveller"
                  name="number_of_traveller"
                  rules={[
                    {
                      required: true,
                      message: "min 1 traveller required",
                    },
                  ]}
                >
                  <InputNumber min={1} />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Package"
                  name="trip_id"
                  rules={[
                    {
                      required: true,
                      message: "Package is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={packages}
                  />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Total Price (US$)"
                  name="total_price"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Payable Percentage"
                  name="payable_percentage"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} xl={12} hidden>
                <div className="switch-group">
                  <Form.Item
                    label="Status"
                    valuePropName="checked"
                    name="is_custom"
                  >
                    <Switch
                      checkedChildren="Yes"
                      unCheckedChildren="No"
                    />
                  </Form.Item>
                 
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    packageList: state.packages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackageList: (tokenData) => dispatch(getList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookingForm);
