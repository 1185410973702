export const BOOKING_LIST_LOADING = "BOOKING_LIST_LOADING";
export const GET_BOOKING_LIST = "GET_BOOKING_LIST";
export const GET_BOOKING_LIST_ERROR = "GET_BOOKING_LIST_ERROR";

export const GET_BOOKING_BY_ID_LOADING = "GET_BOOKING_BY_ID_LOADING";
export const GET_BOOKING_BY_ID_SUCCESS = "GET_BOOKING_BY_ID_SUCCESS";
export const GET_BOOKING_BY_ID_ERROR = "GET_BOOKING_BY_ID_ERROR";

export const SENDING_EMAIL = "SENDING_EMAIL";
export const EMAIL_SENT = "EMAIL_SENT";
export const SENDING_EMAIL_ERROR = "SENDING_EMAIL_ERROR";


export const GET_INVOICE_LOADING = "GET_INVOICE_LOADING";
export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_ERROR = "GET_INVOICE_ERROR";


export const INQUIRY_LIST_LOADING = "INQUIRY_LIST_LOADING";
export const GET_INQUIRY_LIST = "GET_INQUIRY_LIST";
export const GET_INQUIRY_LIST_ERROR = "GET_INQUIRY_LIST_ERROR";