import {
  REEL_LIST_LOADING,
  GET_REEL_LIST_ERROR,
  GET_REEL_LIST,
  GET_REEL_BY_ID_LOADING,
  GET_REEL_BY_ID_SUCCESS,
  GET_REEL_BY_ID_ERROR,
  DELETEING_REEL,
  DELETE_REEL,
  DELETE_REEL_ERROR,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REEL_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_REEL_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETEING_REEL:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_REEL_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_REEL_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
      case DELETE_REEL:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_REEL_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_REEL_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case DELETE_REEL_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    default:
      return state;
  }
};

export default reducer;