import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getInquiryList } from "../../Redux/Booking/actions";

function InquiryList({ useAuth, getList, list }) {

  const tableConfig = {
    title: "All Inquiry",
    is_inquiry: true,
    tableColumns: [
      {name: "Name", selector: 'name', width: '70%'},
      {name: "Email", selector: 'email', width: '15%'},
      {name: "Date", selector: 'created_at', width: '15%'},
      {name: "ID", selector: 'id', width: '2%'},
      {name: "operation", selector: 'id', width: '2%'}
    ],
    filterByColumn: 'type',
    searchByColumn: 'full_name',
    noDataMessage: "No inquiry exists"
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.booking,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getInquiryList(tokenData)), 
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InquiryList);
