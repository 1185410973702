import React from "react";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { BASE_URL } from "../../../Redux/urls";

const TextEditor = (props) => {
  // const handleEditorChange = (e) => {
  //   console.log("e", e);
  //   console.log("Content was updated:", e.target.getContent());
  // };

  const example_image_upload_handler = async (
    blobInfo,
    success,
    failure,
    progress
  ) => {
    var xhr, formData;
    formData = new FormData();
    formData.append("attachment", blobInfo.blob(), blobInfo.filename());

    await axios
      .post(`${BASE_URL}api/v1/admin/attachment/add`, formData, {
        headers: {
          "Content-Type": "application/json",
          Authorization:
            localStorage.getItem("token_type") +
            " " +
            localStorage.getItem("access_token"),
        },
      })
      .then(function (response) {
        success(response.data.attachment);
      })
      .catch(function (error) {
        failure(
          "Image upload failed due to a XHR Transport error. Code: " +
          error.status
        );
      });
  };

  const editorConfig = {
    ...(props.simpleTollbar
      ? {
        height: 500,
        menubar: false,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount", 'contextmenu'
        ],

        contextmenu:
          "paste copy | link image inserttable | cell row column deletetable",
        toolbar:
          "H2 H3 H4 | " +
          "bold italic blockquote | bullist numlist" +
          "removeformat link",
      }
      : props.isBlog ? {
        height: 500,
        menubar: true,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount", 'contextmenu'
        ],

        contextmenu:
          "paste copy | link image inserttable | cell row column deletetable",
        toolbar:
          "undo redo | formatselect | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | image media | " +
          "block",
        setup: function (editor) {
          editor.ui.registry.addButton('block', {
            text: 'Add Package',
            onAction: function (_) {
              editor.insertContent('<div class="package-block" style="font-weight:bold;display: block;padding: .25em 0;background: #e9f9ff;color: #0a80af;">[block]()</div><p></p>');
            }
          });
        },
        image_advtab: true,
        image_caption: true,
        paste_data_images: false,
        images_upload_handler: example_image_upload_handler,
      } : {
        height: 500,
        menubar: true,
        plugins: [
          "advlist autolink lists link image charmap print preview anchor",
          "searchreplace visualblocks code fullscreen",
          "insertdatetime media table paste code help wordcount", 'contextmenu'
        ],

        contextmenu:
          "paste copy | link image inserttable | cell row column deletetable",
        toolbar:
          "undo redo | formatselect | " +
          "bold italic backcolor | alignleft aligncenter " +
          "alignright alignjustify | bullist numlist outdent indent | " +
          "removeformat | image media",
        image_advtab: true,
        image_caption: true,
        paste_data_images: false,
        images_upload_handler: example_image_upload_handler,
      }),
  };
  return (
    <>
      <Editor
        apiKey={process.env.REACT_APP_TinyMCE_apiKey}
        value={props.value}
        initialValue={props.initialValue ? props.initialValue : null}
        init={{
          ...editorConfig,
          ...(props.inline && {
            inline: true,
            referrer_policy: "origin",
          }),
          ...(props.placeholder && { placeholder: props.placeholder }),
        }}
        onEditorChange={props.onChange}
        //onChange={handleEditorChange}
      />
    </>
  );
};

export default TextEditor;
