export const CREATING_BLOG = "CREATING_BLOG";
export const UPDATING_BLOG = "UPDATING_BLOG";
export const BLOG_CREATED = "BLOG_CREATED";
export const BLOG_UPDATED = "BLOG_UPDATED";
export const ERROR_BLOG_CREATE = "ERROR_BLOG_CREATE";
export const ERROR_BLOG_UPDATE = "ERROR_BLOG_UPDATE";


export const CHECKING_SLUG = "CHECKING_SLUG";
export const SLUG_CHECKED = "SLUG_CHECKED";
export const ERROR_SLUG_CHECKED = "ERROR_SLUG_CHECKED";

export const UPDATED_RECENTLY = "UPDATED_RECENTLY";
export const SET_UPDATED_RECENTLY_TO_FALSE = "SET_UPDATED_RECENTLY_TO_FALSE";

