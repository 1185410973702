import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  Switch,
  TreeSelect,
  Alert,
  Select,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";
import ImageField from "../Core/ImageField";

import { getList } from "../../Redux/Package/actions";

//import "antd/dist/antd.css";
import DashboardActionBar from "../DashboardActionBar";
const { TreeNode } = TreeSelect;
const { Option } = Select;

const ReelForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getList,
  list,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [short_description, setShortDescription] = useState(
    isUpdateForm && data.dataById?.data.page_description
  );
  //const [featured_image, setFeaturedImage] = useState();
  const [featured_image, setFeaturedImage] = useState(
    isUpdateForm
      ? data.dataById &&
          data.dataById.data.featured &&
          data.dataById.data.featured.full_path
      : null
  );
  const [image_crop_open, setImageCropOpen] = useState(false);
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading || data.loading || list.loading;

  // useEffect(() => {
  //   if (isUpdateForm && !loading) {
  //     setFeaturedImage(data.dataById?.data?.thumbnail)
  //   }
  // }, [isUpdateForm, loading, data.dataById?.data?.thumbnail])

  const loadPackage = async () => {
    await getList(tokenData);
  };

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
    if (!list.data) {
      loadPackage();
    }
  }, [list.data]);

  useEffect(() => {
    const alert = document.querySelector('.custom-alert')
    setTimeout(() => {
      alert.classList.add('fadeout')
    }, 5000)

  }, [selectedForm.error])

  const articleListOptions = list.data?.data?.map((itm) => {
    return {
      value: itm.id,
      label: itm.package_title,
      // ...(itm.children?.length !== 0 && {
      //   children: itm.children?.map((a) => {
      //     return {
      //       value: a.id,
      //       label: a.page_title,
      //       ...(a.children?.length !== 0 && {
      //         children: a.children?.map((b) => {
      //           return {
      //             value: b.id,
      //             label: b.page_title,
      //           };
      //         }),
      //       }),
      //     };
      //   }),
      // }),
    };
  });

  const clearForm = () => {
    form.resetFields();
    setShortDescription("");
    setFeaturedImage("");
  };

  if (isUpdateForm) {
    setFieldsValue({
      model_type: 'package',//data.dataById?.data.model_type,
      model_item_id: data.dataById?.data.parent_id,
      caption: data.dataById?.data.caption,
      alt_text: data.dataById?.data.alt_text,
      featured_image: data.dataById?.data.thumbnail,
      reel_url: data.dataById?.data.reel_url,
      short_description: data.dataById?.data.short_description,
      status: data.dataById?.data.status === 1 ? true : false,
      is_featured: data.dataById?.data.is_featured === 1 ? true : false,
    });
  }

  const reelAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const axios = require("axios");
      if (action === "add") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  status: values.status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  thumbnail: response.data,
                },
                tokenData
              )
            });
        } else {
          createEntity(
            {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
            },
            tokenData
          )
        }
      } else if (action === "addandexit") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              createEntity(
                {
                  ...values,
                  status: values.status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  thumbnail: response.data,
                },
                tokenData,
                true,
                history
              );
            });
        } else {
          createEntity(
            {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
            },
            tokenData,
            true,
            history
          );
        }
      } else if (action === "update") {
        if (featured_image) {
          axios
            .get(featured_image, { responseType: "blob" })
            .then((response) => {
              updateEntityInfo(
                {
                  ...values,
                  status: values.status ? 1 : 0,
                  is_featured: values.is_featured ? 1 : 0,
                  thumbnail: response.data,
                },
                tokenData,
                params.id
              ).then(() => {
                loadEntityById();
              });
            });
        } else {
          updateEntityInfo(
            {
              ...values,
              status: values.status ? 1 : 0,
              is_featured: values.is_featured ? 1 : 0,
            },
            tokenData,
            params.id
          ).then(() => {
            loadEntityById();
          });
        }
      }
    });
  };

  const addNewEntry = (e) => {
    reelAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    reelAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    reelAction(e, "update");
  };

  const actionBarOptions = {
    titleToAdd: "Add Reel",
    titleToUpdate: "Update Reel",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update",
    discardLink: "reels",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col xs={24} md={24} lg={18}>
                <div className="common-module bg-white">
                  <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
                    <Col className="gutter-row" xs={24}>
                      <Form.Item
                        label="Select a Model Type"
                        name="model_type"
                        initialValue='package'

                        rules={[
                          {
                            required: true,
                            message: "Model type is required",
                          },
                        ]}
                      >
                        <Select>
                          <Option label='Package' value='package' />
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24}>
                      <Form.Item
                        label="Model Item"
                        name="model_item_id"
                        rules={[
                          {
                            required: true,
                            message: "Package is required",
                          },
                        ]}
                      >
                        <TreeSelect
                          showSearch
                          style={{ width: "100%" }}
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          placeholder="Please select"
                          allowClear
                          treeDefaultExpandAll
                        >
                          <TreeNode value={0} title="No Parent" />
                          {articleListOptions?.map((parent) => {
                            const { value, label, children } = parent;
                            return (
                              <TreeNode key={value} value={value} title={label}>
                                {children?.length !== 0 &&
                                  children?.map((child) => {
                                    const { value, label, children } = child;
                                    return (
                                      <TreeNode
                                        key={value}
                                        value={value}
                                        title={label}
                                      >
                                        {children?.length !== 0 &&
                                          children?.map((gChild) => {
                                            const { value, label } = gChild;
                                            return (
                                              <TreeNode
                                                key={value}
                                                value={value}
                                                title={label}
                                              />
                                            );
                                          })}
                                      </TreeNode>
                                    );
                                  })}
                              </TreeNode>
                            );
                          })}
                        </TreeSelect>
                      </Form.Item>
                    </Col>

                    <Col className="gutter-row" xs={24}>
                      <Form.Item>
                        <ImageField
                          title="Thumbnail"
                          image={
                            featured_image ||
                            (isUpdateForm &&
                              data.dataById?.data.thumbnail)
                          }
                          height={process.env.REACT_APP_REEL_THUMBINAL_HEIGHT}
                          width={process.env.REACT_APP_REEL_THUMBINAL_WIDTH}
                          setImage={(image_data) => {
                            setFeaturedImage(image_data);
                            setImageCropOpen(true);
                          }}
                        />
                        {featured_image && image_crop_open && (
                          <ImageCrop
                            height={
                              process.env.REACT_APP_REEL_THUMBINAL_HEIGHT
                            }
                            width={process.env.REACT_APP_REEL_THUMBINAL_WIDTH}
                            image={featured_image}
                            setCroppedImage={setFeaturedImage}
                            setImageCropOpen={setImageCropOpen}
                          />
                        )}
                      </Form.Item>
                      <Form.Item
                        style={{ marginTop: "-20px" }}
                        label="Alt Text"
                        name="alt_text"
                        rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item
                        label="Caption"
                        name="caption"
                        rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24} >
                      <Form.Item
                        label="Reel Url ID"
                        name="reel_url"
                        rules={[{ required: true, message: "ID required" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col className="gutter-row" xs={24}>
                      <div className="switch-group">
                        <Form.Item
                          label="Status"
                          valuePropName="checked"
                          name="status"
                        >
                          <Switch
                            checkedChildren="Publish"
                            unCheckedChildren="Unpublish"
                          />
                        </Form.Item>
                        <Form.Item
                          label="Is Featured"
                          valuePropName="checked"
                          name="is_featured"
                        >
                          <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col className="gutter-row" xs={24}>
                      <Form.Item label="Short Description" name="short_description">
                        <TextEditor
                          simpleTollbar
                          value={short_description}
                          initialValue={isUpdateForm && short_description}
                          onEditorChange={(e) => {
                            setShortDescription(e.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                </div>

              </Col>

            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    list: state.packages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReelForm);
