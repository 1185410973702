import {
  CREATING_BLOG,
  UPDATING_BLOG,
  BLOG_CREATED,
  BLOG_UPDATED,
  ERROR_BLOG_CREATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
  ERROR_BLOG_UPDATE,
  CHECKING_SLUG,
  SLUG_CHECKED,
  ERROR_SLUG_CHECKED,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const setUpdatedRecentlyToFalse = () => async (dispatch) => {
  dispatch({
    type: SET_UPDATED_RECENTLY_TO_FALSE,
  });
};

export const createEntity =
  (formData, tokenData, exitNow, history) => async (dispatch) => {
    dispatch({
      type: CREATING_BLOG,
    });
    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );
    let webKitFormData = new FormData();

    if (data.categories) {
      for (let i = 0; i < data.categories.length; i++) {
        webKitFormData.append(`categories[${i}]`, data.categories[i]);
      }
    }
    if (data.authors) {
      for (let i = 0; i < data.authors.length; i++) {
        webKitFormData.append(`authors[${i}]`, data.authors[i]);
      }
    }
    if (data.tags) {
      for (let i = 0; i < data.tags.length; i++) {
        webKitFormData.append(`tags[${i}]`, data.tags[i]);
      }
    }

    ["authors", "tags", "categories"].forEach((e) => delete data[e]);

    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });

    await axios
      .post(`${BASE_URL}api/v1/admin/blog/add`, webKitFormData, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: BLOG_CREATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        if (exitNow) {
          history.push("/blogs");
        }
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_BLOG_CREATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };

export const updateEntityInfo =
  (formData, tokenData, id) => async (dispatch) => {
    dispatch({
      type: UPDATING_BLOG,
    });

    let data = Object.fromEntries(
      Object.entries(formData)
        .filter(([_, v]) => v != null)
        .filter(([_, v]) => v !== "")
        .filter(([_, v]) => v !== undefined)
    );

    let webKitFormData = new FormData();

    if (data.categories) {
      for (let i = 0; i < data.categories.length; i++) {
        webKitFormData.append(`categories[${i}]`, data.categories[i]);
      }
    }
    if (data.authors) {
      for (let i = 0; i < data.authors.length; i++) {
        webKitFormData.append(`authors[${i}]`, data.authors[i]);
      }
    }
    if (data.tags) {
      for (let i = 0; i < data.tags.length; i++) {
        webKitFormData.append(`tags[${i}]`, data.tags[i]);
      }
    }
    ["authors", "tags", "categories"].forEach((e) => delete data[e]);

    Object.keys(data).forEach((item, i) => {
      webKitFormData.append(item, data[item]);
    });
    webKitFormData.append("_method", "put");

    await axios
      .post(`${BASE_URL}api/v1/admin/blog/edit/${id}`, webKitFormData, {
        headers: {
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      })
      .then(function (response) {
        dispatch({
          type: BLOG_UPDATED,
          payload: response,
        });
        dispatch({
          type: UPDATED_RECENTLY,
        });
        message.success(response.data.message);
      })
      .catch(function (error) {
        dispatch({
          type: ERROR_BLOG_UPDATE,
          payload: error.response,
        });
        message.error(error.response.data.message);
      });
  };

export const checkSlug = (slug, tokenData) => async (dispatch) => {
  dispatch({
    type: CHECKING_SLUG,
  });

  await axios
    .post(
      `${BASE_URL}api/v1/admin/blog/slug/check`,
      { slug: slug },
      {
        headers: {
          Authorization: `${tokenData.token_type} ${tokenData.access_token}`,
        },
      }
    )
    .then(function (response) {
      dispatch({
        type: SLUG_CHECKED,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: ERROR_SLUG_CHECKED,
        payload: error.response,
      });
    });
};
