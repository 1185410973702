import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  Switch,
  TreeSelect,
  Alert,
  Select,
  Spin,
} from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

//import "antd/dist/antd.css";
import DashboardActionBar from "../DashboardActionBar";
import { getMenuOptions } from "../../Redux/Menu/actions";
import clean from "lodash-clean";
const { TreeNode } = TreeSelect;
const { Option } = Select;

const AlbumForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getMenuOptions,
  menuOptionsList,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [options, setOptions] = useState([]);

  const [form] = Form.useForm();
  const { setFieldsValue, getFieldValue } = form;
  const loading =
    selectedForm.loading || data.loading || menuOptionsList.loading;

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  const loadMenuOptions = async () => {
    await getMenuOptions(tokenData);
  };
  useEffect(() => {
    const alert = document.querySelector(".custom-alert");
    setTimeout(() => {
      alert.classList.add("fadeout");
    }, 5000);
  }, [selectedForm.error]);
  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
    if (!menuOptionsList.options) {
      loadMenuOptions();
    }
  }, [menuOptionsList.options]);

  const optionsList = options?.map((itm) => {
    return {
      value: itm.id,
      label: itm.title,
      ...(itm.children?.length !== 0 && {
        children: itm.children?.map((a) => {
          return {
            value: a.id,
            label: a.title,
            ...(a.children?.length !== 0 && {
              children: a.children?.map((b) => {
                return {
                  value: b.id,
                  label: b.title,
                };
              }),
            }),
          };
        }),
      }),
    };
  });

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      title: data.dataById?.data.title,
      model_type: data.dataById?.data?.model_type,
      model_item_id: data.dataById?.data.model_item_id,
      short_description: data.dataById?.data.short_description,
      status: data.dataById?.data.status === 1 ? true : false,
    });
  }
  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData
        );
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          true,
          history
        );
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
            status: values.status ? 1 : 0,
          },
          tokenData,
          params.id
        ).then(() => loadEntityById());
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const actionBarOptions = {
    titleToAdd: "Add Album",
    titleToUpdate: "Update Album",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update Album",
    taskCompleteAddMessage: "Album is added!",
    taskCompleteUpdateMessage: "Album is updated!",
    discardLink: "albums",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  const optionsData = clean(menuOptionsList.options?.data);

  const onGenderChange = (value) => {
    switch (value) {
      case "page":
        setOptions(optionsData?.page);
        return;
      case "category":
        setOptions(optionsData?.destination);
        return;
      case "blog":
        setOptions(optionsData?.blog);
        return;
        case "member":
          setOptions(optionsData?.member);
          return;
      case "package":
        setOptions(optionsData?.package);
    }
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Title"
                  name="title"
                  rules={[
                    {
                      required: true,
                      message: "Title is required",
                    },
                    { min: 2, max: 255, message: "Invalid title" },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              {!isUpdateForm && (
                <>
                  <Col className="gutter-row" xs={24} sm={12} xl={8}>
                    <Form.Item
                      label="Album Type"
                      name="model_type"
                      rules={[
                        {
                          required: true,
                          message: "Model Type is required",
                        },
                      ]}
                    >
                      <Select onChange={onGenderChange}>
                        <Option value="page">Article</Option>
                        <Option value="category">Category</Option>
                        <Option value="blog">Blog</Option>
                        <Option value="member">Team</Option>
                        <Option value="package">Package</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  {optionsList.length !== 0 && (
                    <Col className="gutter-row" xs={24} sm={12} xl={8}>
                      <Form.Item
                        label="Album assign to"
                        name="model_item_id"
                        rules={[
                          {
                            required: true,
                            message: "This is required",
                          },
                        ]}
                      >
                        <TreeSelect
                        treeLine
                          dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                          placeholder="Please select"
                          allowClear
                          showSearch
                          filterTreeNode={(search, item) => {
                            return item.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
                          }}
                        >
                          {optionsList?.map((parent) => {
                            const { value, label, children } = parent;
                            return (
                              <TreeNode key={value} value={value} title={label}>
                                {children?.length !== 0 &&
                                  children?.map((child) => {
                                    const { value, label, children } = child;
                                    return (
                                      <TreeNode
                                        key={value}
                                        value={value}
                                        title={label}
                                      >
                                        {children?.length !== 0 &&
                                          children?.map((gChild) => {
                                            const { value, label } = gChild;
                                            return (
                                              <TreeNode
                                                key={value}
                                                value={value}
                                                title={label}
                                              />
                                            );
                                          })}
                                      </TreeNode>
                                    );
                                  })}
                              </TreeNode>
                            );
                          })}
                        </TreeSelect>
                      </Form.Item>
                    </Col>
                  )}
                </>
              )}

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Short Description" name="short_description">
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <div className="switch-group">
                  <Form.Item
                    label="Status"
                    valuePropName="checked"
                    name="status"
                  >
                    <Switch
                      checkedChildren="Publish"
                      unCheckedChildren="Unpublish"
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    menuOptionsList: state.menu,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMenuOptions: (tokenData) => dispatch(getMenuOptions(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlbumForm);
