import React, { useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Switch,
  Alert,
  Spin,
  DatePicker,
  InputNumber,
} from "antd";
import { useHistory } from "react-router-dom";
import DashboardActionBar from "../DashboardActionBar";
import { connect } from "react-redux";
import { getList } from "../../Redux/Package/actions";
import countryCode from "./../../lib/country-code.json";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";

const { Option } = Select;
const { RangePicker } = DatePicker;

const PaymentForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getPackageList,
  packageList,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [form] = Form.useForm();
  const { setFieldsValue, getFieldValue } = form;
  const loading = selectedForm.loading || data.loading || packageList.loading;

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  const loadPackages = async () => {
    await getPackageList(tokenData);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
    if (!packageList.data) {
      loadPackages();
    }
  }, [packageList.data]);

  useEffect(() => {
    const alert = document.querySelector(".custom-alert");
    setTimeout(() => {
      alert.classList.add("fadeout");
    }, 5000);
  }, [selectedForm.error]);

  let packages = packageList.data?.data.map((itm) => {
    return {
      label: itm.package_title,
      value: itm.id,
    };
  });

  packages &&
    packages.unshift({
      label: "Custom Package",
      value: 0,
    });

  const countryNameList = countryCode.map((itm) => {
    return {
      label: itm.name,
      value: itm.name,
    };
  });

  const countryCodeList = countryCode.map((itm) => {
    return {
      label: itm.dial_code,
      value: itm.dial_code,
    };
  });

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      if (action === "add") {
        createEntity(
          {
            ...values,
          },
          tokenData
        );
      } else if (action === "addandexit") {
        createEntity(
          {
            ...values,
          },
          tokenData,
          true,
          history
        );
      } else if (action === "update") {
        updateEntityInfo(
          {
            ...values,
          },
          tokenData,
          params.id
        );
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  // const onFinish = (values) => {
  //   if (isAddForm) {
  //     createEntity(
  //       {
  //         ...values,
  //         status: values.status ? 1 : 0,
  //       },
  //       tokenData
  //     ).then(() =>{
  //       message.success('Category is added!')
  //       loadCategory()
  //     });
  //   } else if (isUpdateForm) {
  //     updateEntityInfo(
  //       {
  //         ...values,
  //         status: values.status ? 1 : 0,
  //         is_featured: values.is_featured ? 1 : 0,
  //         description,
  //         featured_image: response.data,
  //       },
  //       tokenData,
  //       params.id
  //     ).then(() => {
  //       message.success('Category is updated!')
  //       loadEntityById()
  //       loadCategory()
  //     });
  //   }
  // };

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      package_id: data.dataById?.data.trip_id,
      custom_title: data.dataById?.data.custom_title,
      customer_total: data.dataById?.data.customer_total,
      customer_name: data.dataById?.data.customer_name,
      customer_email: data.dataById?.data.customer_email,
      percent: data.dataById?.data.percent,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Create Cart",
    titleToUpdate: "Update Cart",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update",
    taskCompleteAddMessage: "Cart is added!",
    taskCompleteUpdateMessage: "Cart is updated!",
    discardLink: "payments",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module bg-white mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Traveller Full Name"
                  name="customer_name"
                  rules={[
                    {
                      required: true,
                      message: "Full Name is required",
                    },
                    { min: 5, max: 255, message: "Invalid Name" },
                  ]}
                >
                  <Input placeholder="Full Name" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Customer E-mail"
                  name="customer_email"
                  rules={[
                    {
                      type: "email",
                      message: "The input is not valid E-mail!",
                    },
                    {
                      required: true,
                      message: "Please input your E-mail!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Total Price (US$)"
                  name="customer_total"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Payable Percentage"
                  name="percent"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <InputNumber />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Package"
                  name="package_id"
                  rules={[
                    {
                      required: true,
                      message: "Package is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={packages}
                  />
                </Form.Item>
              </Col>

              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.package_id !== currentValues.package_id
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("package_id") === 0 ? (
                    <Col className="gutter-row" xs={24} sm={12} xl={8}>
                      <Form.Item
                        label="Custom Package Title"
                        name="custom_title"
                        rules={[
                          {
                            required: true,
                            message: "Custom Package is required",
                          },
                        ]}
                      >
                        <TextArea placeholder="Custom Package" rows={2} />
                      </Form.Item>
                    </Col>
                  ) : null
                }
              </Form.Item>

              {/* {getFieldValue("package_id") == 0 && (
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label="Custom Package Title"
                    name="custom_title"
                    rules={[
                      {
                        required: true,
                        message: "Custom Package is required",
                      },
                    ]}
                  >
                    <TextArea placeholder="Custom Package" rows={2} />
                  </Form.Item>
                </Col>
              )} */}
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    packageList: state.packages,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackageList: (tokenData) => dispatch(getList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);
