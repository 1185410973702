import {
  CREATING_BLOG,
  ERROR_BLOG_CREATE,
  UPDATING_BLOG,
  BLOG_CREATED,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
  BLOG_UPDATED,
  ERROR_BLOG_UPDATE,
  CHECKING_SLUG,
  SLUG_CHECKED,
  ERROR_SLUG_CHECKED,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  loadedRecently: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATING_BLOG:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case CHECKING_SLUG:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case BLOG_CREATED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case SLUG_CHECKED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case ERROR_BLOG_CREATE:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    case ERROR_SLUG_CHECKED:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    case UPDATING_BLOG:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case BLOG_UPDATED:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
    case ERROR_BLOG_UPDATE:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case SET_UPDATED_RECENTLY_TO_FALSE:
      return {
        ...state,
        loadedRecently: false,
      };
    case UPDATED_RECENTLY:
      return {
        ...state,
        loadedRecently: true,
      };
    default:
      return state;
  }
};

export default reducer;
