import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Select,
  Col,
  Row,
  Switch,
  Alert,
  Rate,
  DatePicker,
  Spin,
} from "antd";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import slugify from "react-slugify";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import ImageCrop from "../ImageCrop";
import ImageField from "../Core/ImageField";

import { getList as blogList } from "../../Redux/Blog/actions";
import { getList as commentList } from "../../Redux/Comment/actions";
import moment from "moment";

//import "antd/dist/antd.css";
import TextArea from "antd/lib/input/TextArea";
import DashboardActionBar from "../DashboardActionBar";

const { Option } = Select;

const CommentForm = ({
  loginData,
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  getBlogList,
  blogListData,
  getCommentList,
  commentListData,
}) => {
  const tokenData = loginData.data;
  const history = useHistory();
  const [comment, setComment] = useState(
    isUpdateForm && data.dataById?.data.comment
  );
  const [avatar, setAvatar] = useState(
    isUpdateForm
      ? data.dataById &&
          data.dataById.data.avatar &&
          data.dataById.data.avatar.full_path
      : null
  );
  const [image_crop_open, setImageCropOpen] = useState(false);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading =
    selectedForm.loading || blogListData.loading || commentListData.loading; // || data.loading
  const loadBlog = async () => {
    await getBlogList(tokenData);
  };
  const loadComment = async () => {
    await getCommentList(tokenData);
  };

  const loadEntityById = async () => {
    await getEntityById(tokenData, params.id);
  };

  useEffect(() => {
    if (isUpdateForm) {
      loadEntityById();
    }
    if (!blogListData.data) {
      loadBlog();
    }
    if (!commentListData.data) {
      loadComment();
    }
  }, [blogListData.data, commentListData.data]);

  useEffect(() => {
    const alert = document.querySelector(".custom-alert");
    setTimeout(() => {
      alert.classList.add("fadeout");
    }, 5000);
  }, [selectedForm.error]);

  const blogListOptions = blogListData.data?.data.map((itm) => {
    return {
      value: itm.id,
      label: itm.title,
    };
  });

  let commentListOptions = commentListData.data?.data.map((itm) => {
    return {
      value: itm.id,
      label: itm.commentor_name,
    };
  });

 
  if (isUpdateForm) {
    setFieldsValue({
      blog_id: data.dataById?.data.blog_id,
      parent_id: data.dataById?.data.parent_id,
      commentor_name: data.dataById?.data.commentor_name,
      commentor_email: data.dataById?.data.commentor_email,
      commentor_address: data.dataById?.data.commentor_address,
      commentor_contact: data.dataById?.data.commentor_contact,
      comment: data.dataById?.data.comment,
      approval_status: data.dataById?.data.approval_status === 1 ? true : false,
      // avatar: data.dataById?.data.avatar?.thumb_path,
      // avatar_alt: data.dataById?.data.avatar?.avatar_alt,
      // avatar_caption: data.dataById?.data.avatar?.avatar_caption
    });
  }

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const axios = require("axios");
      values.approval_status = values.approval_status ? 1 : 0;
      if (action === "add") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity(
              {
                ...values,
                avatar: response.data,
              },
              tokenData
            );
          });
        } else {
          createEntity(
            {
              ...values,
            },
            tokenData
          );
        }
      } else if (action === "addandexit") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity(
              {
                ...values,
                avatar: response.data,
              },
              tokenData,
              true,
              history
            );
          });
        } else {
          createEntity(
            {
              ...values,
            },
            tokenData,
            true,
            history
          );
        }
      } else if (action === "update") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            updateEntityInfo(
              {
                ...values,
                avatar: response.data,
              },
              tokenData,
              params.id
            ).then(() => {
              loadEntityById();
            });
          });
        } else {
          updateEntityInfo(
            {
              ...values,
            },
            tokenData,
            params.id
          ).then(() => {
            loadEntityById();
          });
        }
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setComment("");
    setAvatar("");
  };

  const actionBarOptions = {
    titleToAdd: "Add Comment",
    titleToUpdate: "Update Comment",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Comment",
    taskCompleteAddMessage: "Comment is added!",
    taskCompleteUpdateMessage: "Comment is updated!",
    discardLink: "blog/comments",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3 bg-white">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Name"
                  name="commentor_name"
                  //title="Auto Generate from Url Title"
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Email"
                  name="commentor_email"
                  //title="Auto Generate from Url Title"
                  rules={[
                    {
                      required: true,
                      message: "Email is required",
                    },
                  ]}
                >
                  <Input type="email" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Address" name="commentor_address">
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Phone" name="commentor_contact">
                  <Input type="tel" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Select a Blog"
                  name="blog_id"
                  rules={[
                    {
                      required: true,
                      message: "Blog is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={blogListOptions}
                  ></Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Parent" name="parent_id">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    optionLabelProp="label"
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                  >
                    <Option value={0} label="No Parent"> No Parent</Option>
                    {commentListOptions?.map((itm) => {
                      return(
                        <Option value={itm.value} label={itm.label}>{itm.label}</Option>
                      )
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={16}>
                <div className="switch-group">
                  <Form.Item
                    label="Approval Status"
                    valuePropName="checked"
                    name="approval_status"
                  >
                    <Switch
                      checkedChildren="Publish"
                      unCheckedChildren="Unpublish"
                    />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
          <div className="common-module pt-0">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} xl={24}>
                <Form.Item label="Comment" name="comment">
                  <TextEditor
                    value={comment}
                    initialValue={isUpdateForm && comment}
                    onEditorChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>

              {/* <Col className="gutter-row" xs={24} xl={10}>
              <div className="card bg-white mb-4 mb-xl-0">
                <div className="card-body">
                  <Form.Item>
                    <ImageField
                      title="Avatar"
                      image={
                        avatar ||
                        (isUpdateForm && data.dataById?.data.avatar?.full_path)
                      }
                      height={150}
                      width={150}
                      setImage={(image_data) => {
                        setAvatar(image_data);
                        setImageCropOpen(true);
                      }}
                    />
                    {avatar && image_crop_open && (
                      <ImageCrop
                        image={avatar}
                        height={150}
                        width={150}
                        setCroppedImage={setAvatar}
                        setImageCropOpen={setImageCropOpen}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: "-20px" }}
                    label="Alt Text"
                    name="avatar_alt"
                    rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Caption"
                    name="avatar_caption"
                    rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>
            </Col> */}
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    blogListData: state.blog,
    commentListData: state.comment,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBlogList: (tokenData) => dispatch(blogList(tokenData)),
    getCommentList: (tokenData) => dispatch(commentList(tokenData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommentForm);
