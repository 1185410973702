import {
  COMMENT_LIST_LOADING,
  GET_COMMENT_LIST_ERROR,
  GET_COMMENT_LIST,
  GET_COMMENT_BY_ID_LOADING,
  GET_COMMENT_BY_ID_SUCCESS,
  GET_COMMENT_BY_ID_ERROR,
  DELETEING_COMMENT,
  DELETE_COMMENT,
  DELETE_COMMENT_ERROR,
  DELETED_COMMENT_LIST_LOADING,
  GET_DELETED_COMMENT_LIST,
  GET_DELETED_COMMENT_LIST_ERROR,
  PERMA_DELETEING_COMMENT,
  PERMA_DELETE_COMMENT,
  PERMA_DELETE_COMMENT_ERROR,
  RESTORE_COMMENT_LIST_LOADING,
  RESTORE_COMMENT_LIST,
  RESTORE_COMMENT_LIST_ERROR,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COMMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_COMMENT_BY_ID_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETEING_COMMENT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case DELETED_COMMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case PERMA_DELETEING_COMMENT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case RESTORE_COMMENT_LIST_LOADING:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case GET_COMMENT_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case GET_COMMENT_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
    case GET_DELETED_COMMENT_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case DELETE_COMMENT:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case PERMA_DELETE_COMMENT:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };
    case RESTORE_COMMENT_LIST:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case GET_COMMENT_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_COMMENT_BY_ID_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case GET_DELETED_COMMENT_LIST_ERROR:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case DELETE_COMMENT_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case PERMA_DELETE_COMMENT_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };
    case RESTORE_COMMENT_LIST_ERROR:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    default:
      return state;
  }
};

export default reducer;