export const COMMENT_LIST_LOADING = "COMMENT_LIST_LOADING";
export const GET_COMMENT_LIST = "GET_COMMENT_LIST";
export const GET_COMMENT_LIST_ERROR = "GET_COMMENT_LIST_ERROR";

export const GET_COMMENT_BY_ID_LOADING = "GET_COMMENT_BY_ID_LOADING";
export const GET_COMMENT_BY_ID_SUCCESS = "GET_COMMENT_BY_ID_SUCCESS";
export const GET_COMMENT_BY_ID_ERROR = "GET_COMMENT_BY_ID_ERROR";

export const DELETEING_COMMENT = "DELETEING_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const DELETE_COMMENT_ERROR = "DELETE_COMMENT_ERROR";

export const DELETED_COMMENT_LIST_LOADING = 'DELETED_COMMENT_LIST_LOADING';
export const GET_DELETED_COMMENT_LIST= 'GET_DELETED_COMMENT_LIST';
export const GET_DELETED_COMMENT_LIST_ERROR = 'GET_DELETED_COMMENT_LIST_ERROR';

export const PERMA_DELETEING_COMMENT = 'PERMA_DELETEING_COMMENT';
export const PERMA_DELETE_COMMENT = 'PERMA_DELETE_COMMENT';
export const PERMA_DELETE_COMMENT_ERROR = 'PERMA_DELETE_COMMENT_ERROR';

export const RESTORE_COMMENT_LIST_LOADING = 'RESTORE_COMMENT_LIST_LOADING';
export const RESTORE_COMMENT_LIST = 'RESTORE_COMMENT_LIST';
export const RESTORE_COMMENT_LIST_ERROR = 'RESTORE_COMMENT_LIST_ERROR';
