import React from "react";
import { connect } from "react-redux";

import DashboardAdd from "../../Components/Layouts/DashboardAdd";
import PaymentForm from "../../Components/Forms/PaymentForm";
import {
  createEntity,
  updateEntityInfo,
  setUpdatedRecentlyToFalse,
} from "../../Redux/PaymentForm/actions";
import { getEntityById } from "../../Redux/Payment/actions";

function Add({
  useAuth,
  getEntityById,
  createEntity,
  updateEntityInfo,
  setUpdatedRecentlyToFalse,
  data,
  loginData,
  selectedForm,
}) {
  return (
    <DashboardAdd useAuth={useAuth}>
      <PaymentForm
        isAddForm
        {...{
          getEntityById,
          createEntity,
          updateEntityInfo,
          setUpdatedRecentlyToFalse,
          data,
          loginData,
          selectedForm,
        }}
      />
    </DashboardAdd>
  );
}

const mapStateToProps = (state) => {
  return {
    data: state.booking,
    loginData: state.loginData,
    selectedForm: state.bookingForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEntityById: (tokenData, id) => dispatch(getEntityById(tokenData, id)),
    createEntity: (formData, tokenData, exitAfter, history) =>
      dispatch(createEntity(formData, tokenData, exitAfter, history)),
    updateEntityInfo: (formData, tokenData, id) =>
      dispatch(updateEntityInfo(formData, tokenData, id)),
    setUpdatedRecentlyToFalse: () => dispatch(setUpdatedRecentlyToFalse()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Add);
