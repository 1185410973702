import React, { Children, useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import {
  getList as getMenuList,
  deleteEntityById, sortEntityById
} from "../../Redux/Menu/actions";
import { getList as getMenuTypeList } from "../../Redux/MenuType/actions";
import clean from "lodash-clean";

function List({
  useAuth,
  getList,
  list,
  onDelete,
  onSort,
  loginData,
  getMenuTypeList,
  menuTypelist,
}) {
  let tokenData = loginData.data;
  const [filter_options, setFilterOptions] = useState();

  useEffect(() => {
    if (!list.data) {
      getList(tokenData);
    }
    if (!menuTypelist.data) {
      getMenuTypeList(tokenData);
    }
    if (menuTypelist.data) {
      setFilterOptions(
        menuTypelist.data?.data.map((e) => {
          return { value: e.id, text: e.type_title };
        })
      );
    }
  }, [list.data, menuTypelist.data]);

  const dataList = clean(list);
  const tableConfig = {
    title: "Menu",
    filterOptionTitle: "Menu Type",
    filterOptions: filter_options,
    applyFilter: true,
    is_menu: true,
    tableColumns: [
      { name: "Title", selector: "item_title", width: "77%" },
      { name: "Status", selector: "status", width: "3%" },
      { name: "Order", selector: "order", width: "3%" },
      {name: "operation", selector: 'id', width: '5%'},
      { name: "Modified Date", selector: "updated_at", width: "10%" },
      { name: "ID", selector: "id", width: "5%" },
    ],
    filterByColumn: "type_id",
    searchByColumn: "item_title",
    addPath: "/menu/add",
    updatePath: "/menu/update",
    addTypePath: "/menu-type/add",
    addTypeText: "Add Menu Type",
    noDataMessage: "No menu exists",
    deleteBtnText: "Delete",
    updateLinkColumn: "item_title",
    deleteItemsByIdList: (idList) => {
      onDelete(idList);
    },
    sortItemsByIdList: (idList) => {
      onSort(idList);
    } 
  };

  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={dataList}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.menu,
    menuTypelist: state.menuType,
    loginData: state.loginData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getMenuList(tokenData)),
    getMenuTypeList: (tokenData) => dispatch(getMenuTypeList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList)),
    onSort: (idList) => dispatch(sortEntityById(idList)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
