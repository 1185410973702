
import React from "react";
import { connect } from "react-redux";
import DashboardList from "../../Components/Layouts/DashboardList";
import { getList, deleteEntityById } from "../../Redux/Comment/actions";
import clean from 'lodash-clean'

function List({ useAuth, getList, list, onDelete}) {
  const tableConfig = {
    title: "Comments",
    filterOptionTitle: "Category Type",
    filterOptions: [
      "category",
    ],
    defaultFilterValue: 'admin',
    applyFilter: false,
    tableColumns: [
      {name: "Name", selector: 'commentor_name', width: '70%'},
      {name: "Status", selector: 'approval_status', width: '3%'},
      {name: "Modified Date", selector: 'updated_at', width: '17%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'commentor_name',
    searchByColumn: 'commentor_name',
    updatePath: "/blog/comment/update", 
    addPath: "/blog/comment/add",
    noDataMessage: "No comment exists",
    updateLinkColumn: 'commentor_name',
    deleteBtnText: 'Delete',
    deleteItemsByIdList: (idList) => {
      onDelete(idList)
    },
  }

  const dataList = clean(list)

  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={dataList}
      tableConfig={tableConfig}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    list: state.comment,  
    loginData: state.loginData, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getList(tokenData)),
    onDelete: (idList) => dispatch(deleteEntityById(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(List);
