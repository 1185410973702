import {
  CREATING_PAYMENT,
  PAYMENT_CREATED,
  ERROR_PAYMENT_CREATE,
  UPDATING_PAYMENT,
  PAYMENT_UPDATED,
  ERROR_PAYMENT_UPDATE,
  UPDATED_RECENTLY,
  SET_UPDATED_RECENTLY_TO_FALSE,
} from "./types";

const INITIAL_STATE = {
  data: undefined,
  loading: false,
  error: false,
  loadedRecently: false,
  dataById: undefined,
};

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREATING_PAYMENT:
      return {
        ...state,
        loading: true,
        error: false,
      };

    case PAYMENT_CREATED:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        error: false,
      };

    case ERROR_PAYMENT_CREATE:
      return {
        ...state,
        data: undefined,
        loading: false,
        error: action.payload.data,
      };

    case UPDATING_PAYMENT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case PAYMENT_UPDATED:
      return {
        ...state,
        loading: false,
        dataById: action.payload.data,
        error: false,
      };
    case ERROR_PAYMENT_UPDATE:
      return {
        ...state,
        dataById: undefined,
        loading: false,
        error: action.payload.data,
      };
    case SET_UPDATED_RECENTLY_TO_FALSE:
      return {
        ...state,
        loadedRecently: false
      }
    case UPDATED_RECENTLY:
      return {
        ...state,
        loadedRecently: true
      }
    default:
      return state;
  }
};

export default reducer;
