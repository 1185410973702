import {
  REEL_LIST_LOADING,
  GET_REEL_LIST,
  GET_REEL_LIST_ERROR,
  GET_REEL_BY_ID_LOADING,
  GET_REEL_BY_ID_SUCCESS,
  GET_REEL_BY_ID_ERROR,
  DELETEING_REEL,
  DELETE_REEL,
  DELETE_REEL_ERROR,
} from "./types";
import axios from "axios";
import { BASE_URL } from "../urls";
import { message } from "antd";

export const getList = (tokenData) => async (dispatch) => {
  dispatch({
    type: REEL_LIST_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/reels/all`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_REEL_LIST,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_REEL_LIST_ERROR,
        payload: error.response,
      });
    });
};

export const getEntityById = (tokenData, id) => async (dispatch) => {
  dispatch({
    type: GET_REEL_BY_ID_LOADING,
  });
  await axios
    .get(`${BASE_URL}api/v1/admin/reels/single/${id}`, {
      headers: {
        'Authorization': `${tokenData.token_type} ${tokenData.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: GET_REEL_BY_ID_SUCCESS,
        payload: response,
      });
    })
    .catch(function (error) {
      dispatch({
        type: GET_REEL_BY_ID_ERROR,
        payload: error.response,
      });
    });
};



export const deleteEntityById = (idList) => async (dispatch) => {
  dispatch({
    type: DELETEING_REEL,
  });
  let bulk = {bulk: idList}
  await axios
    .post(`${BASE_URL}api/v1/admin/reels/bulk/delete`, bulk, {
      headers: {
        'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
      }
    })
    .then(function (response) {
      dispatch({
        type: DELETE_REEL,
        payload: response,
      })      
      message.success(response.data.message);
    })
    .catch(function (error) {
      dispatch({
        type: DELETE_REEL_ERROR,
        payload: error.response,
      })
      message.error(error.response.data.message);
    });
};

// export const permanentDeleteEntity = (idList) => async (dispatch) => {
//   dispatch({
//     type: PERMA_DELETEING_REEL,
//   });
//   let bulk = {bulk: idList}
//   await axios
//     .post(`${BASE_URL}api/v1/admin/menu/REEL/bulk/perma-delete`, bulk, {
//       headers: {
//         'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
//       }
//     })
//     .then(function (response) {
//       dispatch({
//         type: PERMA_DELETE_REEL,
//         payload: response,
//       })
      
//       message.success(response.data.message);
//     })
//     .catch(function (error) {
//       dispatch({
//         type: PERMA_DELETE_REEL_ERROR,
//         payload: error.response,
//       })
      
//       message.error(error.response.data.message);
//     });
// };

// export const restoretDeleteEntity = (idList) => async (dispatch) => {
//   dispatch({
//     type: RESTORE_REEL_LIST_LOADING,
//   });
//   let bulk = {bulk: idList}
//   await axios
//     .post(`${BASE_URL}api/v1/admin/menu/REEL/bulk/restore`, bulk, {
//       headers: {
//         'Authorization': `${localStorage.token_type} ${localStorage.access_token}`
//       }
//     })
//     .then(function (response) {
//       dispatch({
//         type: RESTORE_REEL_LIST,
//         payload: response,
//       })      
//       message.success(response.data.message);
//     })
//     .catch(function (error) {
//       dispatch({
//         type: RESTORE_REEL_LIST_ERROR,
//         payload: error.response,
//       }) 
//       message.error(error.response.data.message);
//     });
// };