import React from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import DashboardList from "../../Components/Layouts/DashboardList";
import {getDeletedList} from "../../Redux/Comment/actions"; 

function DeletedList({ useAuth, getList, list, onDelete, onRestore}) {
  const tableConfig = {
    title: "Deleted Comments(s)",
    filterOptionTitle: "Category Type",
    filterOptions: [
      "category",
    ],
    defaultFilterValue: 'admin',
    applyFilter: false,
    tableColumns: [
      {name: "Order", selector: 'order', width: '3%'},
      {name: "Status", selector: 'status', width: '3%'},
      {name: "Name", selector: 'commentor_name', width: '70%'},
      {name: "Modified Date", selector: 'updated_at', width: '17%'},
      {name: "ID", selector: 'id', width: '5%'}
    ],
    filterByColumn: 'full_name',
    searchByColumn: 'commentor_name',
    updatePath: "/comment/update", 
    addPath: "/comment/add",
    restorePath: "/comments",
    noDataMessage: "No comments exists",
    updateLinkColumn: 'full_name',
    deleteBtnText: 'Permanent Delete',
    // deleteItemsByIdList: (idList) => {
    //   onDelete(idList)
    // },
    // restoreItemsByIdList: (idList) => {
    //   onRestore(idList)
    // },
  }
  return (
    <DashboardList
      useAuth={useAuth}
      getList={getList}
      dataToList={list}
      tableConfig={tableConfig}
    />
  );
}


const mapStateToProps = (state) => {
  return {
    list: state.comment,
    loginData: state.loginData, 
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getList: (tokenData) => dispatch(getDeletedList(tokenData)),
    //onDelete: (idList) => dispatch(permanentDeleteEntity(idList)),
   // onRestore: (idList) => dispatch(restoretDeleteEntity(idList))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletedList);
